var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/logging.ts
var logging_exports = {};
__export(logging_exports, {
  VeChainSDKLogger: () => VeChainSDKLogger
});

// src/logger/log-logger/log-logger.ts
var _logLogFunction = {
  log: (data) => {
    const messagesAsString = data.messages.map((message) => `- ${message}`).join("\n");
    console.log(
      `
****************** EVENT: ${data.title} ******************
` + messagesAsString + `
`
    );
  }
};

// src/logger/error-logger/error-logger.ts
var _logErrorFunction = {
  log: (error) => {
    console.error(
      `
****************** ERROR ON: ${error.methodName} ******************
- Error message: '${error.errorMessage}'
- Error data:
`,
      error.data,
      `
- Internal error:
`,
      error.innerError,
      `
`
    );
  }
};

// src/logger/warning-logger/warning-logger.ts
var _logWarningFunction = {
  log: (data) => {
    const messagesAsString = data.messages.map((message) => `- ${message}`).join("\n");
    console.warn(
      `
****************** WARNING: ${data.title} ******************
` + messagesAsString + `
`
    );
  }
};

// src/logger/vechainSDKLogger.ts
var VeChainSDKLogger = (loggerType) => {
  if (loggerType === "error")
    return _logErrorFunction;
  if (loggerType === "warning")
    return _logWarningFunction;
  return _logLogFunction;
};
export {
  VeChainSDKLogger,
  logging_exports as logging
};
