var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/errors.ts
var errors_exports = {};
__export(errors_exports, {
  CannotFindTransaction: () => CannotFindTransaction,
  CertificateSignature: () => CertificateSignature,
  ContractDeploymentFailed: () => ContractDeploymentFailed,
  FunctionNotImplemented: () => FunctionNotImplemented,
  InvalidAbiDataToEncodeOrDecode: () => InvalidAbiDataToEncodeOrDecode,
  InvalidAbiFragment: () => InvalidAbiFragment,
  InvalidAbiSignatureFormat: () => InvalidAbiSignatureFormat,
  InvalidAddress: () => InvalidAddress,
  InvalidBloom: () => InvalidBloom,
  InvalidBloomParams: () => InvalidBloomParams,
  InvalidCastType: () => InvalidCastType,
  InvalidDataType: () => InvalidDataType,
  InvalidHDNode: () => InvalidHDNode,
  InvalidHDNodeMnemonic: () => InvalidHDNodeMnemonic,
  InvalidHTTPRequest: () => InvalidHTTPRequest,
  InvalidKeystore: () => InvalidKeystore,
  InvalidKeystoreParams: () => InvalidKeystoreParams,
  InvalidRLP: () => InvalidRLP,
  InvalidSecp256k1MessageHash: () => InvalidSecp256k1MessageHash,
  InvalidSecp256k1PrivateKey: () => InvalidSecp256k1PrivateKey,
  InvalidSecp256k1Signature: () => InvalidSecp256k1Signature,
  InvalidTransactionField: () => InvalidTransactionField,
  JSONRPCInternalError: () => JSONRPCInternalError,
  JSONRPCInvalidParams: () => JSONRPCInvalidParams,
  JSONRPCInvalidRequest: () => JSONRPCInvalidRequest,
  JSONRPCMethodNotFound: () => JSONRPCMethodNotFound,
  JSONRPCParseError: () => JSONRPCParseError,
  JSONRPCProviderError: () => JSONRPCProviderError,
  JSONRPCServerError: () => JSONRPCServerError,
  NotDelegatedTransaction: () => NotDelegatedTransaction,
  PollExecution: () => PollExecution,
  UnavailableTransactionField: () => UnavailableTransactionField,
  UnsupportedOperation: () => UnsupportedOperation,
  VechainSDKError: () => VechainSDKError,
  assertInnerError: () => assertInnerError,
  createErrorMessage: () => createErrorMessage,
  stringifyData: () => stringifyData
});

// src/helpers/helpers.ts
var stringifyData = (data) => {
  const getCircularReplacer = () => {
    const seen = /* @__PURE__ */ new WeakSet();
    return (_key, value) => {
      if (typeof value === "object" && value !== null && _key !== "") {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
  return JSON.stringify(data, getCircularReplacer());
};
function assertInnerError(error) {
  if (error instanceof Error) {
    return error;
  }
  return new Error(
    `Inner error is not an instance of Error. Object:
	${stringifyData(error)}`
  );
}
function createErrorMessage(methodName, errorMessage, inputData, innerError) {
  return `Method '${methodName}' failed.
-Reason: '${errorMessage}'
-Parameters: 
	${stringifyData(inputData)}
-Internal error: 
	${innerError?.message !== void 0 ? innerError.message : "No internal error given"}`;
}

// src/available-errors/sdk-error.ts
var VechainSDKError = class extends Error {
  constructor(methodName, errorMessage, data, innerError) {
    super(
      createErrorMessage(
        methodName,
        errorMessage,
        data,
        innerError === void 0 ? void 0 : assertInnerError(innerError)
      )
    );
    this.methodName = methodName;
    this.errorMessage = errorMessage;
    this.data = data;
    this.innerError = innerError;
  }
};

// src/available-errors/abi/abi.ts
var InvalidAbiDataToEncodeOrDecode = class extends VechainSDKError {
};
var InvalidAbiFragment = class extends VechainSDKError {
};
var InvalidAbiSignatureFormat = class extends VechainSDKError {
};

// src/available-errors/address/address.ts
var InvalidAddress = class extends VechainSDKError {
};

// src/available-errors/bloom/bloom.ts
var InvalidBloom = class extends VechainSDKError {
};
var InvalidBloomParams = class extends VechainSDKError {
};

// src/available-errors/certificate/certificate.ts
var CertificateSignature = class extends VechainSDKError {
};

// src/available-errors/contract/contract.ts
var ContractDeploymentFailed = class extends VechainSDKError {
};

// src/available-errors/data/data.ts
var InvalidDataType = class extends VechainSDKError {
};
var UnsupportedOperation = class extends VechainSDKError {
};

// src/available-errors/function/function.ts
var FunctionNotImplemented = class extends VechainSDKError {
};

// src/available-errors/hdnode/hdnode.ts
var InvalidHDNodeMnemonic = class extends VechainSDKError {
};
var InvalidHDNode = class extends VechainSDKError {
};

// src/available-errors/http/http.ts
var InvalidHTTPRequest = class extends VechainSDKError {
};

// src/available-errors/keystore/keystore.ts
var InvalidKeystore = class extends VechainSDKError {
};
var InvalidKeystoreParams = class extends VechainSDKError {
};

// src/available-errors/poll/poll.ts
var PollExecution = class extends VechainSDKError {
};

// src/available-errors/provider/provider.ts
var JSONRPCProviderError = class extends VechainSDKError {
  constructor(methodName, code, message, data, innerError) {
    super(methodName, message, { code, message, data }, innerError);
    this.methodName = methodName;
    this.innerError = innerError;
  }
};
var JSONRPCParseError = class extends JSONRPCProviderError {
};
var JSONRPCInvalidRequest = class extends JSONRPCProviderError {
};
var JSONRPCMethodNotFound = class extends JSONRPCProviderError {
};
var JSONRPCInvalidParams = class extends JSONRPCProviderError {
};
var JSONRPCInternalError = class extends JSONRPCProviderError {
};
var JSONRPCServerError = class extends JSONRPCProviderError {
};

// src/available-errors/rlp/rlp.ts
var InvalidRLP = class extends VechainSDKError {
};

// src/available-errors/secp256k1/secp256k1.ts
var InvalidSecp256k1PrivateKey = class extends VechainSDKError {
};
var InvalidSecp256k1MessageHash = class extends VechainSDKError {
};
var InvalidSecp256k1Signature = class extends VechainSDKError {
};

// src/available-errors/transaction/transaction.ts
var UnavailableTransactionField = class extends VechainSDKError {
};
var InvalidTransactionField = class extends VechainSDKError {
};
var NotDelegatedTransaction = class extends VechainSDKError {
};
var CannotFindTransaction = class extends VechainSDKError {
};

// src/available-errors/vcdm/vcdm.ts
var InvalidCastType = class extends VechainSDKError {
};
export {
  CannotFindTransaction,
  CertificateSignature,
  ContractDeploymentFailed,
  FunctionNotImplemented,
  InvalidAbiDataToEncodeOrDecode,
  InvalidAbiFragment,
  InvalidAbiSignatureFormat,
  InvalidAddress,
  InvalidBloom,
  InvalidBloomParams,
  InvalidCastType,
  InvalidDataType,
  InvalidHDNode,
  InvalidHDNodeMnemonic,
  InvalidHTTPRequest,
  InvalidKeystore,
  InvalidKeystoreParams,
  InvalidRLP,
  InvalidSecp256k1MessageHash,
  InvalidSecp256k1PrivateKey,
  InvalidSecp256k1Signature,
  InvalidTransactionField,
  JSONRPCInternalError,
  JSONRPCInvalidParams,
  JSONRPCInvalidRequest,
  JSONRPCMethodNotFound,
  JSONRPCParseError,
  JSONRPCProviderError,
  JSONRPCServerError,
  NotDelegatedTransaction,
  PollExecution,
  UnavailableTransactionField,
  UnsupportedOperation,
  VechainSDKError,
  assertInnerError,
  createErrorMessage,
  errors_exports as errors,
  stringifyData
};
